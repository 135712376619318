<template>
  <div class="main-page fake-attack">
    <custom-header customBack @onBack="goBack" v-if="fakeStep != 3"></custom-header>
    <div class="main-container" v-if="(activeTab == ATTACK_VALUE && videoAttackStep == 1) || (activeTab == FAKE_VALUE && fakeStep == 1)">
      <div class="desc">
        <h4>伪造攻击与防御</h4>
      </div>
      <van-tabs v-model="activeTab" @change="changeTab" class="custom-headers-com" swipeable>
        <van-tab :name="ATTACK_VALUE">
          <template #title>
            <div class="tab-headers has-sub">音视频</div>
            <div class="tab-headers-sub">伪造攻击与防御</div>
          </template>
          <div class="detection-inner">
            <div class="image-box">
              <div class="img-cover">
                <img :src="attackImgUrl" alt="" class="main-img">
              </div>
              <div class="text-tip">被攻击方</div>
            </div>
            <div class="image-box" >
              <div class="img-cover">
                <video :src="attackVideoUrl"  v-if="attackVideoUrl"
                  style="object-fit:contain" ref="attackVideoUrlRef" 
                  webkit-playsinline playsinline x5-playsinline x5-video-player-type="h5"
                  controls autoplay loop class="video">您的浏览器不支持 video 标签。</video>
                <div v-else class="attack-tip" @click="chooseExample">请选择攻击方视频</div>
              </div>
              <div class="text-tip">攻击方</div>
            </div>
            <div class="qr-scanner" v-if="attackLoading">
              <div class="box">
                <div class="line"></div>
              </div>
            </div>
          </div>
        </van-tab>
        <van-tab :name="FAKE_VALUE">
          <template #title>
            <div class="tab-headers has-sub">身份认证</div>
            <div class="tab-headers-sub">伪造攻击与防御</div>
          </template>
          <div class="detection-inner">
            <div v-if="fakeStep == 1" class="fake-face">
              <div class="image-box">
                <van-image fit="contain" :src="imgUrl" lazy-load class="img-cover">
                  <template v-slot:loading>
                    <van-loading class="fs-80 lazy-loading" color="#007BFF"/>
                  </template>
                </van-image>
                <div class="qr-scanner" v-if="fakeLoading">
                  <div class="box">
                    <div class="line"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-tab>
      </van-tabs>
      
      <div class="footer-panel" v-if="(activeTab == ATTACK_VALUE && videoAttackStep == 1) || (activeTab == FAKE_VALUE && fakeStep == 1)">
        <div class="btn-group">
          <van-button type="info" class="choose-btn" :disabled="customLoading" @click="chooseExample">选择{{activeTab == ATTACK_VALUE ? '视频' : '照片'}}</van-button>
          <van-button type="info" class="choose-btn" :disabled="customLoading" @click="generateVideo">生成视频</van-button>
        </div>
        <custom-footer></custom-footer>
      </div>
    </div>
    
   
    <div  v-if="videoAttackStep == 2" class="attack-result">
      <div class="attack-top top">视频</div>
      <div class="image-box">
        <div class="img-cover">
          <video :src="resultVideoUrl" style="object-fit:contain" ref="resultVideoUrlRef" controls autoplay loop x5-playsinline playsinline webkit-playsinline x5-video-player-type="h5" x5-video-player-fullscreen=""  x5-video-orientation="portraint" class="video">您的浏览器不支持 video 标签。</video>
        </div>
        <div class="ai-tip">视频由AI技术生成</div>
      </div>
      <van-button type="info" class="choose-btn" :disabled="attackLoading" @click="checkInfo">睿鉴伪造检测</van-button>
    </div>

    <div v-if="fakeStep == 2" class="generate-video">
      <div class="desc">
        <h4>生成视频</h4>
      </div>
      <div class="video-box">
        <van-loading color="#007BFF" class="video-loading fs-80 lazy-loading"/>
        <!-- 就是因为黄总的手机上需要video外面嵌套一层video-inner才能显示是视频，不知缘由 -->
        <div class="video-inner">
          <video :src="vivoDetection.all" ref="allMediaRef" loop x5-playsinline="" playsinline="" webkit-playsinline="" x5-video-player-type="h5" muted x5-video-player-fullscreen="" x5-video-orientation="portraint">您的浏览器不支持 video 标签。</video>
        </div>
      </div>
      <div class="result-panel">
        <h4>视频动作</h4>
        <ul class="action-list">
          <li v-for="(item, index) in generateList" :key="index">
            <div class="video-box small-video">
              <van-loading color="#007BFF" class="video-loading fs-50 lazy-loading"/>
              <!-- 就是因为黄总的手机上需要video外面嵌套一层video-inner才能显示是视频，不知缘由 -->
              <div class="video-inner">
                <video :src="vivoDetection[item.key]" ref="mediaListRef" loop x5-playsinline="" playsinline="" webkit-playsinline="" x5-video-player-type="h5" muted x5-video-player-fullscreen="" x5-video-orientation="portraint">您的浏览器不支持 video 标签。</video>
              </div>
            </div>
            <p>{{item.text}}</p>
          </li>
        </ul>
        <van-button type="info" class="choose-btn" @click="startDiscern">开始认证</van-button>
      </div>
      <div class="ai-tip">视频由AI技术生成</div>
    </div>

    <div class="face-detection" v-if="fakeStep == 3">
      <div class="header">
        <span class="icon" @click="closePage">
          <van-icon name="cross" />
        </span>
        <h4>刷脸认证</h4>
        <span class="icon">
          <img :src="hasVolume ? openVolumeImg : closeVolumeImg" class="icon-volume" @click="handleVolume" v-if="step == 1"/>
        </span>
      </div>

      <div v-if="step == 1">
        <audio controls hidden preload="auto" ref="audioRef" loadedmetadata="onLoadedmetadata">
          <source :src="sourceUrl" type="audio/mpeg">
          您的浏览器不支持 audio 元素。
        </audio>
        <div class="circle-box">
          <div class="cover-font" v-if="activeFirstStep && activeFirstStep.key && activeFirstSteNum != 3">
            <div class="tip">
              <div>{{activeFirstStep.text}}</div>
              <van-count-down :time="time" format="ss s" class="time" ref="countDown"/>
            </div>
          </div>
          <van-circle v-model="currentRate" :rate="rate" :stroke-width="30" :speed="speed" :class="activeLoading ? 'active' : ''"></van-circle>
          <div class="video-box">
            <video :src="videoUrl" x5-playsinline="" playsinline="" webkit-playsinline="" x5-video-player-type="h5" muted ref="mediaRef" x5-video-player-fullscreen="" x5-video-orientation="portraint">您的浏览器不支持 video 标签。</video>
          </div>
        </div>

        <div class="step-tip">
          <div v-if="!activeFirstStep">请正对屏幕</div>
          <div v-if="activeFirstStep && activeFirstStep.num > 0 && activeFirstSteNum != 3">还需完成<span class="num">{{activeFirstStep.num}}</span>个动作</div>
          <div v-if="activeFirstStep && activeFirstStep.num == -1">即将完成认证</div>
        </div>
      </div>
      
      <div class="loading-panel" v-if="step == 2">
        <van-circle v-model="currentRate" :rate="100" :stroke-width="60" :speed="50" class="circle-loading">{{times}}s</van-circle>
        <div class="tip">
          <h4>正在等待返回结果…</h4>
          <p>返回结果前，请不要重复动作</p>
        </div>
      </div>

      <div v-if="step == 3">
        <!-- 认证成功 -->
        <div class="result-panel success-panel" v-if="success">
          <img src="@/assets/img/icon-success.png" class="icon-result"/>
          <h4 class="result-font">认证成功</h4>
          <div class="content">
            <van-button type="info" class="btn primary-btn" @click="onRjDetection">开启睿鉴伪造检测</van-button>
            <van-button type="info" class="btn normal-btn" @click="goHomePage">返回首页</van-button>
            <div class="text-btn_grey" @click="detectionAgain">重新检测</div>
          </div>
        </div>

        <!-- 认证失败 -->
        <div class="result-panel fail-panel" v-else>
          <img src="@/assets/img/icon-fail.png" class="icon-result"/>
          <h4 class="result-font">认证失败</h4>
          <div class="content">
            <h4>检测结果</h4>
            <div class="search-condition">
              <div class="paltform-item item">硬件平台</div>
              <div class="unspeed-item item">未加速版</div>
              <div class="speed-item item">加速版</div>
            </div>
            <ul class="platform-inner">
              <li class="clearfix" v-for="item in choosePlatform" :key="item.id">
                <div class="paltform-item item">
                  <div class="desc-info">
                    <div class="sub-name sub-title">{{item.cpu}}</div>
                  </div>
                  <div class="desc-info">
                    <div class="sub-name">{{item.gpu}}<img @click="openDialog('详细型号', item)" src="@/assets/img/deep-question.png" class="icon-question"/></div>
                  </div>
                </div>
                <div class="unspeed-item item">
                  <van-loading color="#1989fa" class="fs-60 lazy-loading" v-if="loading && (!resultData[item.id] || !resultData[item.id].normal)"/>

                  <div class="desc-info" v-if="resultData[item.id]">
                    <div class="fail-info" v-if="resultData[item.id].normal && !resultData[item.id].normal.success">
                      <div class="error">
                        <img :src="failImg" alt="">
                        <span class="tip" v-if="resultData[item.id].normal && resultData[item.id].normal.msg">{{resultData[item.id].normal.msg}}</span>
                      </div>
                      <van-button class="again-btn" type="info" size="medium" @click="checkAgain(item)">重新检测</van-button>
                    </div>

                    <div class="success-info" v-if="resultData[item.id].normal && resultData[item.id].normal.success">
                      <label>平均推理耗时</label>
                      <div class="average-time">
                        <span>{{resultData[item.id].normal.avgTime}}</span>
                        ms/帧
                        <!-- <img @click="openDialog('详细数据', {desc: `<div>平均推理耗时：${resultData[item.id].normal.avgTime}ms/帧</br>模型推理耗时：${resultData[item.id].normal.inferenceTime}ms</br>图像上传耗时：${resultData[item.id].normal.networkTime}ms</div>`})" src="@/assets/img/deep-question.png" class="icon-question"/> -->
                      </div>
                    </div>
                  </div>
                </div>
                <div class="speed-item item">
                  <van-loading color="#1989fa" class="fs-60 lazy-loading" v-if="loading && (!resultData[item.id] || !resultData[item.id].speed)"/>

                  <div class="desc-info" v-if="resultData[item.id]">
                    <div class="fail-info" v-if="resultData[item.id].speed && !resultData[item.id].speed.success">
                      <div class="error">
                        <img :src="failImg" alt="">
                        <span class="tip" v-if="resultData[item.id].speed && resultData[item.id].speed.msg">{{resultData[item.id].speed.msg}}</span>
                      </div>
                      <van-button class="again-btn" type="info" size="medium" @click="checkAgain(item, 1)">重新检测</van-button>
                    </div>

                    <div class="success-info" v-if="resultData[item.id].speed && resultData[item.id].speed.success">
                      <label>平均推理耗时</label>
                      <div class="average-time">
                        <span>{{resultData[item.id].speed.avgTime}}</span>
                        ms/帧
                        <!-- <img @click="openDialog('详细数据', {desc: `<div>平均推理耗时：${resultData[item.id].speed.avgTime}ms/帧</br>模型推理耗时：${resultData[item.id].speed.inferenceTime}ms</br>图像上传耗时：${resultData[item.id].speed.networkTime}ms</div>`})" src="@/assets/img/deep-question.png" class="icon-question"/> -->
                      </div>
                    </div>
                  </div>
                </div>
              </li>
            </ul>
            <van-button type="info" class="btn normal-btn" @click="goHomePage">返回首页</van-button>
          </div>
        </div>
      </div>
      <!-- 详情数据 -->
      <van-dialog v-model="showDialog" :title="dialogTitle" class="detail-dialog">
        <div v-html="dialogDesc"></div>
      </van-dialog>
    </div>

    <choose-example v-if="chooseShow" :dataList="exampleDataList" @goBack="hideExample" @onDetect="onDetect" :type="activeTab == ATTACK_VALUE ? 'videoAttack' : 'fakeFace'"></choose-example>
  </div>
</template>

<script>
import CustomHeader from "@/components/CustomHeader.vue";
import CustomFooter from "@/components/CustomFooter.vue";
import ChooseExample from "@/components/ChooseExample.vue";
import { Toast, Uploader } from 'vant';
import { DATA, DEFENSE } from '@/remote';

const FAKE_VALUE = '11';
const ATTACK_VALUE = '12';

// 硬件平台列表
const PLATFORM_LIST = [{
  "id": 2,
  "name": "国产服务器（DCU）",
  "cpu": "国产服务器",
  "gpu": "DCU",
  "desc": "<div>处理器：Hygon C86 7185，主频2.6GHz</br>显卡：DCU，显存16GB</br>理论峰值：13.9TFLOPS@FP32 </div>"
}, {
  "id": 3,
  "name": "进口服务器（英伟达V100）",
  "cpu": "进口服务器",
  "gpu": "英伟达V100",
  "desc": "处理器：Intel(R) Xeon(R) Gold 6240，主频2.6GHz</br>显卡：NVIDIA Tesla V100-PCIE，显存32GB</br>理论峰值：14TFLOPS@FP32"
}]

// 默认选中项
const CHOOSE_PLATFORM = [2,3];

const FIRST_STEP_LIST = [
  {key: 'blink', text: '请眨眨眼', num: 2},
  {key: 'nod', text: '请点点头', num: 1},
  {key: 'mouth', text: '请张张嘴', num: -1},
]

const MP3_OBJ = {
  blink: require('@/assets/mp3/blink.mp3'),
  mouth: require('@/assets/mp3/mouth.mp3'),
  nod: require('@/assets/mp3/nod.mp3'),
}

export default {
  name: "fake-attack",
  components: {
    "custom-header": CustomHeader,
    "custom-footer": CustomFooter,
    "choose-example": ChooseExample,
  },
  data(){
    return {
      activeTab: ATTACK_VALUE,
      FAKE_VALUE,
      ATTACK_VALUE,

      chooseShow: false,
      exampleObj: {},
      exampleDataList: [],

      attackVideoUrl: '', //攻击方 视频
      attackImgUrl: 'http://saas.ruijianai.com:9311/mai/image-files/tianjin/tianjinVideo/%E6%96%B9%E6%A1%88%E4%BA%8C%E5%8E%9F%E7%85%A7%E7%89%87.png.jpg',
      attackLoading: false,
      videoAttackStep: 1,
      resultVideoUrl: '',

      imgUrl: '',
      fakeStep: 1,
      fakeLoading: false,
      vivoDetection: {}, // 分解的视频
      generateList: [
        {key: 'shakeHeadLeft', text: '向左转头'},
        {key: 'shakeHeadRight', text: '向右转头'},
        {key: 'blink', text: '眨眼'},
        {key: 'nod', text: '点头'},
        {key: 'mouth', text: '张嘴'}
      ],

      time: 15 * 1000,
      activeLoading: false,
      currentRate: 0,
      rate: 80,
      speed: 0,
      videoUrl: '',
      sourceUrl: MP3_OBJ.blink,
      hasVolume: true, // 是否开启声音
      openVolumeImg: require('@/assets/img/icon-volume-open.png'),
      closeVolumeImg: require('@/assets/img/icon-volume-close.png'),

      loading: false,
      choosePlatform: PLATFORM_LIST.filter(item => (CHOOSE_PLATFORM.slice()).includes(item.id)).map(item => item),
      resultData: {}, // 检测结果
      realFetchNum: 0, // 正常检测请求数量
      totalFetchNum: 0,
      againRealFetchNum: 0, // 重新检测请求数量
      againtTotalFetchNum: 0,

      step: 1,
      activeFirstStep: '',
      activeFirstSteNum: 0,
      success: false, // 第三方true，睿鉴false
      times: 3,

      showDialog: false,
      dialogDesc: '',
      dialogTitle: '详细型号',
      isRjDetection: false, // 是否睿鉴检测
    };
  },
  computed: {
    customLoading() {
      return this.activeTab == ATTACK_VALUE ? this.attackLoading : this.fakeLoading;
    }
  },

  mounted() {
    let {activeTab} = this.$route.query;
    if (activeTab) {
      this.activeTab = activeTab;
    }
    this.getDemo(FAKE_VALUE);
    this.getDemo(ATTACK_VALUE);
  },
  methods: {
    goBack(){
      if ((this.activeTab == ATTACK_VALUE && this.videoAttackStep == 1) || (this.activeTab == FAKE_VALUE && this.fakeStep == 1)) {
        setTimeout(() => {
          this.$router.push({
            name: 'Home',
          })
        }, 0)
      }
      if (this.activeTab == ATTACK_VALUE && this.videoAttackStep == 2) {
        this.videoAttackStep = 1;
      }

      if (this.activeTab == FAKE_VALUE && this.fakeStep == 2) {
        this.fakeStep = 1;
      }
    },

    // 选择视频
    changeVideo(){
      this.chooseShow = true;
      this.videoAttackStep = 0;
    },

    // 切换tab
    changeTab(value) {
      this.activeTab = value;
    },

    getDemo(fileType) {
      DATA.getDemo({fileType}).then(res => {
        if (res.code == 200) {
          this.exampleObj[fileType] = res.data || [];
          let fileUrl = (res.data || [])[0];
          if (fileType == FAKE_VALUE) {
            this.imgUrl = fileUrl;
          }
        }
      })
    },

    // 选择示例
    chooseExample() {
      this.exampleDataList = this.exampleObj[this.activeTab];
      this.chooseShow = true;
    },

    hideExample() {
      this.chooseShow = false;
    },

    onDetect(fileUrl) {
      this.chooseShow = false;
      if (this.activeTab == ATTACK_VALUE) {
        this.chooseShow = false;
        this.attackVideoUrl = fileUrl;
        setTimeout(() => {
          this.$refs.attackVideoUrlRef.play();
        }, 0)
        this.videoAttackStep = 1;
      } else {
        this.imgUrl = fileUrl;
      }
    },

    // 生成视频
    generateVideo() {
      this.activeTab == ATTACK_VALUE ? this.generateVideoByAttack() : this.generateVideoByFake();
    },

    // 音视频——生成视频
    generateVideoByAttack() {
      if (!this.attackVideoUrl) {
        Toast("请选择攻击视频");
        return;
      }
      this.attackLoading = true;
      let suffixData = this.attackVideoUrl.split(".");
      let suffixType = suffixData[suffixData.length - 1 ];
      let params = {
        video: this.attackVideoUrl,
        videoType: suffixType
      };

      DEFENSE.videoCheck(params).then(res => {
        if (res.code == 200) {
          this.attackLoading = false;
          let fileUrl = (res.data || {}).video;
          this.resultVideoUrl = fileUrl;
          setTimeout(() => {
            this.$refs.resultVideoUrlRef.play();
          }, 0)
          this.videoAttackStep = 2;
        } else {
          //没有检测出人脸
          this.attackLoading = false;
          this.videoAttackStep = 1;
          Toast("请上传清晰的单人正脸视频");
        }
      },error => {
        this.attackLoading = false;
      })
    },

    // 音视频——睿鉴检测
    checkInfo() {
      this.$router.push({
        name: "CheckInfo",
        query: {
          video: this.resultVideoUrl,
        }
      });
    },

    // 身份认证——生成视频
    generateVideoByFake() {
      this.fakeLoading = true;
      DEFENSE.vivoDetection({image: this.imgUrl}).then(res => {
        this.fakeLoading = false;
        if (res.code == 200) {
           // 为了兼容uc、夸克等浏览器，需要先设置空再设置值，video的border-radius才会生成
          this.vivoDetection = {};
          this.fakeStep = 2;
          // 因为微信浏览器不能autoplay，需要设置手动播放
          setTimeout(() => {
            this.vivoDetection = res.data;
            this.$refs.allMediaRef.play();
            this.$refs.mediaListRef.map(item => item.play());
          }, 0)

          setTimeout(() => {
            this.$refs.allMediaRef.play();
            this.$refs.mediaListRef.map(item => item.play());
          }, 100)
          
        }
      }, res => {
        this.fakeLoading = false;
      })
    },

    // 身份认证——开始认证
    startDiscern() {
      this.isRjDetection = false;
      this.fakeStep = 3;
      this.step = 1;
      this.rate = 80;
      this.speed = 0;
      this.activeLoading = false;
      this.hasVolume = true;
      setTimeout(() => {
        this.step == 1 && this.getStepList(true);
      }, 1000);
    },

    // 身份认证——人脸认证
    getStepList(success) {
      this.resetStepParams(success);
      const totalNum = success ? 3 : 1
      this.$refs.audioRef.addEventListener('play', () => {
        this.$refs.countDown.reset();
        this.$refs.countDown.start();
      }, false)

      this.$refs.audioRef.addEventListener('ended', () => {
        this.$refs.mediaRef.play();
        if (success) {
          setTimeout(() => {
            this.rate = 0;
          }, 1499)
          setTimeout(() => {
            this.rate = 80;
            this.speed = 100;
            this.activeLoading = true;
          }, 1500)
        }
      }, false)

      this.$refs.mediaRef.addEventListener('ended', () => {
        this.activeFirstSteNum++;
        if (this.activeFirstSteNum < totalNum) {
          setTimeout(() => {
            let currentItem = FIRST_STEP_LIST[this.activeFirstSteNum];
            this.activeFirstStep = currentItem;
            this.sourceUrl = MP3_OBJ[currentItem.key];
            this.$refs.audioRef.load();
            this.$refs.audioRef.play();
            this.videoUrl = this.vivoDetection[currentItem.key];
          }, 1000)

          setTimeout(() => {
            this.rate = 80;
            this.speed = 0;
            this.activeLoading = false;
          }, 800)
        } else {
          this.times = 3;
          this.timer = setInterval(()=>{
            this.times--;
            if(this.times == 1){
              clearInterval(this.timer);
              this.step = 3;
              this.success = success;
              if (!success) {
                this.loading = true;
                this.deepfakeCheckBatch();
              }
            }
          }, 1000);
          setTimeout(() => {
            this.step = 2;
            this.activeFirstStep = ''; // 重置
          }, 500)
        }
      }, false)
    },

    // 重置参数
    resetStepParams(success) {
      if (success) {
        this.rate = 80;
        this.speed = 0;
      }
      this.activeFirstSteNum = 0;
      this.activeFirstStep = FIRST_STEP_LIST[0];
      this.videoUrl = this.vivoDetection.blink;
      this.sourceUrl = MP3_OBJ.blink;
      this.$refs.audioRef.load();
      this.$refs.audioRef.play();
      
      // 也是为了兼容奇怪的浏览器
      this.$refs.mediaRef.play();
    },

    // 是否播放声音
    handleVolume() {
      this.$refs.audioRef.muted = !this.$refs.audioRef.muted;
      this.hasVolume = !this.hasVolume;
    },

    // 关闭人脸认证
    closePage() {
      this.fakeStep = 2;
      this.videoUrl = ''; // 清空上一次的视频url
      // 为了兼容uc、夸克等浏览器，需要先设置空再设置值，video的border-radius才会生成
      let vivoDetection = {...this.vivoDetection};
      this.vivoDetection = {};
      // 因为微信浏览器不能autoplay，需要设置手动播放
      setTimeout(() => {
        this.vivoDetection = vivoDetection;
        this.$refs.allMediaRef.play();
        this.$refs.mediaListRef.map(item => item.play());
      }, 0)

      setTimeout(() => {
        this.$refs.allMediaRef.play();
        this.$refs.mediaListRef.map(item => item.play());
      }, 100)
    },

    // 查看详情
    openDialog(dialogTitle, item) {
      this.showDialog = true;
      this.dialogTitle = dialogTitle;
      this.dialogDesc = item.desc;
    },

    // 返回首页
    goHomePage() {
      this.$router.replace({
        name: 'Home'
      })
    },

    // 身份认证——重新检测
    detectionAgain() {
      this.isRjDetection = false;
      this.step = 1;
      this.rate = 80;
      this.speed = 0;
      this.activeLoading = false;
      this.hasVolume = true;
      setTimeout(() => {
        this.getStepList(true);
      }, 500)
    },

    // 身份认证——睿鉴检测
    onRjDetection() {
      this.isRjDetection = true;
      this.step = 1;
      this.rate = 80;
      this.speed = 0;
      this.activeLoading = false;
      this.hasVolume = true;
      Toast({
        message: '已开启睿鉴检测',
        icon: 'checked',
        duration: 2000,
        className: 'rj-flag-toast'
      });
      setTimeout(() => {
        this.getStepList(false);
      }, 500)
    },

    // 身份认证——一体机
    deepfakeCheckBatch() {
      this.totalFetchNum = 4;
      CHOOSE_PLATFORM.map(id => {
        this.deepfakeCheck({macId: id, accelerate: 0});
        this.deepfakeCheck({macId: id, accelerate: 1});
      })
    },

    deepfakeCheck({macId, accelerate, isCheckAgain} = {}) {
      let key = accelerate == 1 ? 'speed' : 'normal';
      DEFENSE.deepfakeCheck({macId, accelerate}).then(res => {
        let {code, data = {}} = res || {}; 
        setTimeout(() => {
          this.resultData = {
            ...this.resultData,
            [macId]: {
              ...this.resultData[macId],
              [key]: {
                avgTime: data.avgTime,
                success: code == 200,
              }
            }
          }
          isCheckAgain ? this.againRealFetchNum++ : this.realFetchNum++;

          if (this.realFetchNum == this.totalFetchNum && this.againRealFetchNum == this.againtTotalFetchNum) {
            this.loading = false;
            // 重置次数
            this.realFetchNum = 0;
            this.totalFetchNum = 0;
            this.againRealFetchNum = 0;
            this.againtTotalFetchNum = 0;
          }
        }, data.avgTime * 20)
      })
    },

  }
};
</script>

<style lang="less">
  @import '../../assets/less/fakeAndAttack';
</style>
